export const extractStaticUrlStr = content => {
  const temp = content
    .trim()
    .replace(/[^\w\s]+/gi, " ")
    .replace(/  +/g, " ")
  const titleUrl = temp
    .split(" ")
    .join("-")
    .toLowerCase()
  return titleUrl.slice(-1) === "-" ? titleUrl.slice(0, -1) : titleUrl
}

export const getCommonItems = (arr1, arr2) => {
  const set1 = new Set(arr1)
  const set2 = new Set(arr2)

  const commonSet = new Set([...set1].filter(item => set2.has(item)))
  return Array.from(commonSet)
}
